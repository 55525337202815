import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
    >
      <div className="container">
        <Box mt={1}>
          <Typography variant="body2" color="text.secondary" align="center">
            Address : 1108, 8th main road, 3rd block, basaveshwara nagar,
            Bengaluru, 560079
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://www.saptarishiastrology.in/">
              saptarishiastrology
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            <Link
              href="https://www.saptarishiastrology.in/terms"
              variant="body2"
              sx={{ alignSelf: "center" }}
            >
              Terms and Condition
            </Link>
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            <Link
              href="https://www.saptarishiastrology.in/policy"
              variant="body2"
              sx={{ alignSelf: "center" }}
            >
              Privacy and refunds policy
            </Link>
          </Typography>
        </Box>
      </div>
    </Box>
  );
}
