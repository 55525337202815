import React, { Component, useEffect, useState } from "react";

const App = () => {
  const [timer, setTimer] = useState();

  for (let i = 0; i < 10; i++) {
    setTimeout(() => {
      console.log(i);
    }, [1000]);
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          border: "1px solid #000",
          width: "200px",
        }}
      >
        <p> Seconds - {timer}</p>{" "}
      </div>
    </div>
  );
};

export default App;
