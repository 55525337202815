import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { Provider } from "react-redux";
import NumerologyPhoneApp from "./num";
import ShowNumber from "./magicNumber/ShowNumber";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PoliciesPage from "./magicNumber/RefundPolicy";
import TermsAndConditions from "./magicNumber/Terms";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ShowNumber />} />
        <Route path="/policy" element={<PoliciesPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
