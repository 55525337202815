import React from "react";

const TermsAndConditions = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        lineHeight: "1.6",
      }}
    >
      <h1 style={{ textAlign: "center" }}>Terms and Conditions</h1>

      <section style={{ marginBottom: "30px" }}>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using the <strong>saptarishiastrology</strong>{" "}
          website, you agree to be bound by these Terms and Conditions. If you
          do not agree, please do not use our services.
        </p>
      </section>

      <section style={{ marginBottom: "30px" }}>
        <h2>2. Service Description</h2>
        <p>
          <strong>saptarishiastrology</strong> provides personalized
          numerology-based insights based on the information you provide. The
          results are for entertainment purposes only and should not be used as
          a substitute for professional advice.
        </p>
      </section>

      <section style={{ marginBottom: "30px" }}>
        <h2>3. User Responsibilities</h2>
        <p>
          You are responsible for providing accurate information to ensure the
          accuracy of our services. Any misuse, unauthorized access, or illegal
          activity associated with our platform will result in termination of
          your access and may involve legal action.
        </p>
      </section>

      <section style={{ marginBottom: "30px" }}>
        <h2>4. Intellectual Property</h2>
        <p>
          All content, including text, graphics, logos, and algorithms used by{" "}
          <strong>saptarishiastrology</strong>, are the intellectual property of
          the platform. Unauthorized reproduction, distribution, or modification
          is strictly prohibited.
        </p>
      </section>

      <section style={{ marginBottom: "30px" }}>
        <h2>5. Limitation of Liability</h2>
        <p>
          <strong>saptarishiastrology</strong> is not liable for any direct,
          indirect, or consequential damages arising from the use or inability
          to use the website. The service is provided "as is" without any
          guarantees or warranties.
        </p>
      </section>

      <section style={{ marginBottom: "30px" }}>
        <h2>6. Refund Policy</h2>
        <p>
          All sales made on <strong>saptarishiastrology</strong> are final, as
          outlined in our Refund Policy. No refunds will be provided after the
          transaction is completed.
        </p>
      </section>

      <section style={{ marginBottom: "30px" }}>
        <h2>7. Changes to Terms</h2>
        <p>
          <strong>saptarishiastrology</strong> reserves the right to update or
          modify these Terms and Conditions at any time without prior notice.
          Continued use of the platform constitutes acceptance of the updated
          terms.
        </p>
      </section>

      <section>
        <h2>8. Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms and
          Conditions, please contact us through our support channels. We are
          happy to assist you.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
