import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";

import AppTheme from "../shared-theme/AppTheme";
import ColorModeSelect from "../shared-theme/ColorModeSelect";
import { Alert } from "@mui/material";
import Background from "../assets/favicon.jpeg";
import Footer from "../Footer/Footer";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const ShowNumberContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  overflowY: "scroll",
  overflow: "hidden",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
  },
}));

export default function ShowNumber(props) {
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState("");
  const [dateError, setDateError] = React.useState(false);
  const [dateErrorMessage, setDateErrorMessage] = React.useState("");
  const [phoneError, setPhoneError] = React.useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = React.useState("");

  const [name, setName] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [terms, setTerms] = React.useState(false);
  const [output, setOutput] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isShow, setIsShow] = React.useState(false);
  const validateInputs = () => {
    setNameErrorMessage("");
    setDateErrorMessage("");
    setPhoneErrorMessage("");

    if (!/^[A-Za-z]{1,20}$/.test(name)) {
      setNameError(true);
      setNameErrorMessage(
        "Name should contain only alphabets and be limited to 20 characters"
      );

      return false;
    }

    const dobPattern =
      /^(19[5-9][0-9]|200[0-9]|2010)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
    if (!dobPattern.test(dob)) {
      setDateError(true);
      setDateErrorMessage(
        "Date of birth should be in DD/MM/YYYY format and year between 1950 and 2010"
      );

      return false;
    }

    if (!/^\d{10}$/.test(phone)) {
      setPhoneError(true);
      setPhoneErrorMessage("Phone number should be 10 digits.");

      return false;
    }
    if (terms == false) {
      alert("Please accept terms and condition");
      return;
    }

    return true;
  };
  const calculateSum = (numStr) => {
    let sum = numStr.split("").reduce((acc, digit) => acc + parseInt(digit), 0);
    while (sum > 9) {
      sum = sum
        .toString()
        .split("")
        .reduce((acc, digit) => acc + parseInt(digit), 0);
    }
    return sum;
  };
  const onCapturePayment = async (response) => {
    if (response.razorpay_payment_id) {
      setIsShow(true);
      calculateNumber();
    }
    setIsLoading(false);
  };

  const payNow = () => {
    setIsLoading(true);
    var options = {
      key: `${process.env.REACT_APP_RZ_API_KEY}`, // Enter the Key ID generated from the Dashboard
      amount: 1 * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Saptarishi Astrology", //your business name
      description: "Test Transaction",
      // order_id: "order_MpCmHuYQs4YJfL", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      theme: {
        color: "#3399cc",
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
        name: name, //your customer's name
        email: name + "@gmail.com",
        contact: phone, //Provide the customer's phone number for better conversion rates
      },
      handler: onCapturePayment,
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      console.log("response", response);
    });
    rzp1.open();

    // e.preventDefault();
  };
  const generateOutput = () => {
    if (!validateInputs()) return;
    payNow();
  };

  function calculateNumber() {
    const dobDigits = dob.replace(/\D/g, "");
    const dobSum = calculateSum(dobDigits);
    const firstTwoDigitsSum = calculateSum(dobDigits.slice(0, 2));
    const phoneSum = calculateSum(phone);

    const newOutput = [
      `Hey ${name}, your lucky numbers according to your date of birth are ${dobSum} and ${firstTwoDigitsSum}`,
      `Your present phone number comes up to ${phoneSum}, so please follow the below guidelines`,
      "Your favorite lucky combo is - You can have any number of 6s, 5s and 9s in your phone number",
      "Please avoid any of these numbers 8, 2 in your phone number!",
      "You can have these numbers once in your phone number which are 1,3,4,7!",
      "You must avoid 7s more than once and 0s in the 5th and last place of your phone number and make sure all the digits added in your phone number must come to 6,5 or 9",
    ];

    setOutput(newOutput);
  }

  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <AppTheme disableCustomTheme={true} {...props}>
        <ShowNumberContainer direction="column" justifyContent="space-between">
          <ColorModeSelect
            sx={{ position: "fixed", top: "1rem", right: "1rem" }}
          />

          <Card sx={{ overflowY: "scroll" }} variant="outlined">
            <Typography
              component="h1"
              variant="h4"
              sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
            >
              Get luckiest phone number
            </Typography>
            <Typography
              component="h5"
              variant="h1"
              sx={{ width: "100%", fontSize: "clamp(0.9rem, 5vw, 1rem)" }}
            >
              Change your life with Numerology
            </Typography>
            {!isShow && (
              <Box
                component="form"
                // onSubmit={generateOutput}
                noValidate
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 2,
                  marginTop: 2,
                }}
              >
                <FormControl>
                  <TextField
                    error={nameError}
                    helperText={nameErrorMessage}
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    color={nameError ? "error" : "primary"}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    error={phoneError}
                    helperText={phoneErrorMessage}
                    id="phonenumber"
                    type="number"
                    name="phone number"
                    placeholder="Mobile number"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    color={phoneError ? "error" : "primary"}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    error={dateError}
                    helperText={dateErrorMessage}
                    id="Dateofbirth"
                    type="date"
                    name="Dateofbirth"
                    placeholder="Date of birth"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    color={dateError ? "error" : "primary"}
                    onChange={(e) => setDob(e.target.value)}
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={terms}
                      onClick={() => setTerms(!terms)}
                      color="primary"
                    />
                  }
                  label="Accept Terms and condition"
                />

                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  onClick={() => generateOutput()}
                  sx={{
                    marginTop: 2,
                  }}
                >
                  Pay now to get Lucky number
                </Button>
              </Box>
            )}
            {isShow && output.length > 0 && (
              <Box>
                <ul>
                  <div className="mt-6 space-y-2">
                    {output.map((point, index) => (
                      <li className="bg-lavender-100 border-lavender-200 text-gray-800">
                        <Typography sx={{}}>{point}</Typography>
                      </li>
                    ))}
                  </div>
                </ul>

                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    setIsShow(false);
                  }}
                  sx={{
                    marginTop: 2,
                  }}
                >
                  Retry
                </Button>
              </Box>
            )}
          </Card>
        </ShowNumberContainer>
        <Footer />
      </AppTheme>
    </div>
  );
}
