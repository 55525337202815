import React from "react";

const PoliciesPage = () => {
  return (
    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <h1 style={{ textAlign: "center" }}>Policies</h1>

      <section style={{ marginBottom: "40px" }}>
        <h2>Refund Policy</h2>
        <p>
          At <strong>saptarishiastrology</strong>, we take pride in delivering
          personalized and instantly accessible services. Due to the nature of
          our offerings, all sales are final, and we do not provide refunds for
          any payments made.
        </p>
        <p>
          By making a purchase on our platform, you acknowledge and agree to
          this refund policy. We recommend carefully reviewing the service
          details before completing your transaction.
        </p>
        <p>
          If you have questions or encounter any issues with your experience,
          feel free to contact our support team. We are here to help resolve any
          concerns to the best of our ability.
        </p>
      </section>

      <section>
        <h2>Privacy Policy</h2>
        <p>
          At <strong>saptarishiastrology</strong>, your privacy is important to
          us. We are committed to protecting your personal information and
          ensuring a secure experience.
        </p>
        <p>
          Any data you provide, such as your date of birth, will be used solely
          for the purpose of generating your lucky number and improving our
          services. We do not share, sell, or disclose your personal information
          to third parties without your consent.
        </p>
        <p>
          For more details about how we handle your data, please reach out to
          us, and we will be happy to provide further clarification.
        </p>
      </section>
    </div>
  );
};

export default PoliciesPage;
